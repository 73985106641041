import React from "react";
import Img from "gatsby-image";

export default props => (
    <section className="small-header">
        <div className="background-image">
            <div className="background-image__content">
                <h1 className="header-title">{ props.title }</h1>
            </div>
            <div className="background-image__image">
                <Img style={{ height: "100%" }} loading="eager" fluid={ props.image } />
            </div>
        </div>
    </section>
);
