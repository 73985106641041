import React from "react";

export default props => (
    <section className="service-content">
        <div className="service-content__container">
            <div className="service-content__content" dangerouslySetInnerHTML={{ __html: props.content }}>
            </div>
        </div>
    </section>
);
