import React from "react";
import SEO from "../components/SEO";
import { graphql } from "gatsby";
import SmallHeader from "../components/SmallHeader";
import ServiceContent from "../components/ServiceContent";

export const data = graphql`query ($slug: String!) {
    service: markdownRemark ( frontmatter: { slug: { eq: $slug} } ) {
        html
        frontmatter { 
            slug
            title
            shortDescription
            backgroundImage {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
}`

export default props => { 
    const { title, backgroundImage, shortDescription } = props.data.service.frontmatter;

    return (
        <>  
        <SEO pageData={{ seoDescription: shortDescription, seoSubtitle: title }} />
        <SmallHeader title={ title } image={ backgroundImage.childImageSharp.fluid } />
        <ServiceContent content={ props.data.service.html } />
        </>
    );
};
